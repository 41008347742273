@import "variables.scss";

$theme-colors: (
  "success": $green,
  "info": $midBlue,
  "warning": $amber,
  "danger": $red,
  "light": $grey,
  "nbBlue": $nbBlue,
  "plum": $plum,
  "grey": $grey,
  "nbOrange": #f80,
  "Authoring": $amber,
  "Approved": $midBlue,
  "Published": $green,
  "Archived": $grey,
  "white": $white,
);

.btn-light:hover {
  background-color: $amber !important;
  color: $white;
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;

  &:hover {
    filter: opacity(0.7);
  }
}

.pseudoLink,
.list-item {
  cursor: pointer;

  &:hover {
    color: $nbBlue;
  }
}

.dropdown-item {
  &:active {
    background-color: $grey !important;
  }
}

//---FONTS---//
@font-face {
  font-family: "Arial";
  src: url(/assets/fonts/Arial.ttf);
}

@font-face {
  font-family: "Quicksand";
  src: url(/assets/fonts/Quicksand-Regular.ttf);
}

body {
  font-family: "Quicksand", sans-serif !important;
}

.nbBlue {
  color: $nbBlue;
}

.ck-toolbar {
  max-width: 250mm;
  border: none !important;
  margin-bottom: 2.5% !important;
}

.toast-body {
  z-index: 1031;
}

/* Rounded buttons the lecturers like */

.rounded-number,
.Rounded-Number {
  float: left;
  border-radius: 50%;
  color: #fff;
  background-color: #2f3858;
  margin: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 4px;
}

.margin-left {
  margin-left: 40px;
  padding-top: 3px;
  margin-top: 0 !important;
}

/* Ck editor overrides */

.ck[contenteditable="true"] {
  padding: 5mm 2mm 0 !important;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0 !important;
}

.ck-content blockquote {
  background-color: #e1eff6;
  border: 2px solid #141414 !important;
  padding: 1%;
  margin-bottom: 1%;
  display: inline-block;
  width: 100%;
  font-style: normal !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 1030 !important;
}

/* Draggable classes */
.drag-wrapper {
  padding: 4px 0;
}

.drag-wrapper.dragging {
  border: 1px solid $grey;
  cursor: move;
  background-color: $amber;
  box-shadow: none;
}

/* Modal classes */
.modal-header {
  font-family: "Quicksand", sans-serif !important;
  background-image: url("/assets/images/underline.png");
  background-size: 100% 7%;
  background-repeat: no-repeat;
  background-position: bottom;
}

// Progress bar
quill-progress-bar-step,
quill-reactive-radio-button {
  display: contents;
}

.section-type-2 {
  .section-title-upper {
    text-transform: uppercase;
  }
}

.section-type-3 {
  .section-title-upper {
    text-transform: uppercase;
  }
}

.section-type-4 {
  .section-title-upper {
    text-transform: uppercase;
  }
}

@import "bootstrap/scss/bootstrap";
@import "forms.scss";
