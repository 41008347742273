//---VARIABLES---//
$alpha: 1;
$white: rgba(255, 255, 255, $alpha);
$black: rgba(20, 20, 20, 1);
$blackAlpha: rgba(20, 20, 20, 0.2);
$red: rgb(195, 13, 0);
$amber: rgba(250, 183, 55, 1);
$green: rgba(67, 143, 102, 1);
$snippetOverlayBg: rgba(255, 51, 102, 1);
$snippetOverlayBtn: rgba(153, 7, 42, 1);
$progressGreen: rgba(77, 153, 112, 1);
$progressGreenText: $green;
$progressOrange: rgba(245, 166, 35, 1);
$saveBtn: rgba(153, 15, 15, 1);
$gutter: 3%;
$maxHeight: 50vh;
$maxWidth: 100vw;
$nbBlue: rgba(11, 70, 100, 1);
$lightBlue: rgba(225, 239, 246, 1);
$grey: rgba(215, 215, 215, 1);
$darkGrey: rgba(115, 115, 115, 1);
$midGrey: rgba(175, 175, 175, 1);
$goldenRod: lightgoldenrodyellow;
$gold: gold;
$scaleSize: 1.2;
$a4Width: 210mm;
$a4MimicWidth: 220mm;
$a4Height: 280mm;
$darkGreen: rgba(25, 48, 60, 1);
$incomplete: rgba(255, 198, 102, 1);
$complete: $green;
$locked: rgba(245, 193, 189, 1);
$textLight: rgba(248, 249, 250, 1);
$primary: rgb(2, 69, 101);
$plum: rgb(96, 0, 74);
$grey: rgb(215, 215, 215);
$midBlue: #0dcaf0;
$charcoal: #323232;
